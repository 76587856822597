import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Link } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
   backgroundColor: '#000',
   height: HEADER.MOBILE_HEIGHT,
   transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
   }),
   [theme.breakpoints.up('md')]: {
      height: HEADER.MAIN_DESKTOP_HEIGHT,
   },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
   left: 0,
   right: 0,
   bottom: 0,
   height: 24,
   zIndex: -1,
   margin: 'auto',
   borderRadius: '50%',
   position: 'absolute',
   width: `calc(100% - 48px)`,
   boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
   const isOffset = useOffSetTop(1);

   const theme = useTheme();

   const { pathname } = useLocation();

   const isDesktop = useResponsive('up', 'md');

   const isHome = pathname === '/';

   return (
      <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent', position: 'sticky' }}>
         <ToolbarStyle
            disableGutters
            sx={{
               ...(isOffset && {
                  ...cssStyles(theme).bgBlur({ color: '#000' }),
                  height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
               }),
            }}
         >
            <Container
               sx={{
                  maxWidth: '700px !important',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            >
               <Logo />

               {/* <Link href="https://docs-minimals.vercel.app/changelog" target="_blank" rel="noopener" underline="none">
                  <Label color="info" sx={{ ml: 1 }}>
                     v3.5.0
                  </Label>
               </Link> */}

               <Box sx={{ flexGrow: 1 }} />

               {/* {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}

               {/* <Button
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  href="https://material-ui.com/store/items/minimal-dashboard/"
               >
                  Purchase Now
               </Button> */}

               <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
               {/* {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />} */}
            </Container>
         </ToolbarStyle>

         {isOffset && <ToolbarShadowStyle />}
      </AppBar>
   );
}
